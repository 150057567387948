import React, { useState } from 'react';
import './DiscoBackground.css';

function App() {
  const [activeTab, setActiveTab] = useState('about');

  const tabs = [
    { id: 'about', label: 'About' },
    { id: 'projects', label: 'Projects' },
    { id: 'experience', label: 'Experience' },
    { id: 'resume', label: 'Resume' },
  ];

  return (
    <div className="bg-gray-900 min-h-screen text-gray-300 font-mono flex flex-col relative">
      <div className="disco-bg"></div>
      <div className="container mx-auto px-4 max-w-5xl flex-grow relative z-10">
        <header className="py-8 border-b border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <img src="/dance.gif" alt="Dancing GIF" className="w-12 h-12 rounded-full bg-gray-700 p-1" />
              <div>
                <h1 className="text-3xl font-bold text-gray-100">&gt;lemondoo</h1>
                <p className="text-sm text-gray-400">#!/usr/bin/env software_developer</p>
              </div>
            </div>
            <div className="flex space-x-4">
              <a href="https://github.com/lemondoo" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100 transition-colors">
                <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/mark-zuckerberg-618bba58/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100 transition-colors">
                <svg className="w-6 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>
            </div>

          </div>
        </header>
        
        <div className="flex py-12">
          <nav className="w-1/4 pr-8">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`block w-full text-left py-2 px-4 mb-2 rounded transition-colors ${
                  activeTab === tab.id ? 'bg-gray-700 text-gray-100' : 'text-gray-400 hover:bg-gray-800'
                }`}
              >
                &gt; {tab.label}
              </button>
            ))}
          </nav>
          
          <main className="w-3/4">
            {activeTab === 'about' && (
              <section>
                <h2 className="text-2xl font-bold mb-4 text-gray-200">&gt; cat about.md</h2>
                <p className="text-lg leading-relaxed text-gray-400">
                  As a software developer, I specialize in crafting elegant, user-centric solutions 
                  that seamlessly blend form and function. My expertise lies in developing 
                  high-performance applications with clean, efficient code. I'm committed to 
                  pushing the boundaries of what's possible in software, always with an eye 
                  towards creating experiences that are both powerful and intuitive.
                </p>
                
                <h3 className="text-xl font-bold mt-8 mb-4 text-gray-200">&gt; ls skills/</h3>
                <div className="flex flex-wrap gap-3">
                  {['JavaScript', 'React', 'Node.js', 'Python', 'Lua (Roblox)'].map((skill) => (
                    <span 
                      key={skill} 
                      className="bg-gray-800 text-gray-300 px-3 py-1 rounded-md font-medium border border-gray-700 transition-all duration-300 ease-in-out hover:bg-gray-700"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </section>
            )}
            
            {activeTab === 'projects' && (
              <section>
                <h2 className="text-2xl font-bold mb-4 text-gray-200">&gt; ./list_projects.sh</h2>
                <p className="text-lg text-gray-400">Project list coming soon...</p>
              </section>
            )}
            
            {activeTab === 'experience' && (
              <section>
                <h2 className="text-2xl font-bold mb-4 text-gray-200">&gt; cat experience.log</h2>
                <p className="text-lg text-gray-400">Work experience details coming soon...</p>
              </section>
            )}
            
            {activeTab === 'resume' && (
              <section>
                <h2 className="text-2xl font-bold mb-4 text-gray-200">&gt; less resume.pdf</h2>
                <p className="text-lg text-gray-400">Resume will be available for download soon...</p>
              </section>
            )}
          </main>
        </div>
      </div>
      
      <footer className="container mx-auto px-4 max-w-5xl mt-auto relative z-10">
        <div className="border-t border-gray-700 py-4">
          <p className="text-center text-gray-600 text-xs">
            /* © 2023 lemondoo. All rights reserved. */
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;